.Map {
  width: 100%;
  min-height: 100vh;
}

// Map marker
.MapMarker svg {
  position: absolute;
  bottom: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 200ms;
  transform: translateX(-50%);
  z-index: 9999;
}

.MapMarker--selected svg {
  width: 40px;
  height: 50px;
}
