.Filter {
  display: flex;
  flex-direction: column;
}

.Filter + .Filter {
  margin-top: $gap * 2;
}

.FilterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;

  border-bottom: 0.1rem solid var(--c-primary);
}

.FilterTitle {
  margin-right: $gap;
  flex-shrink: 0;
}

.FilterSelected {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.FilterInner {
  height: 0;
  overflow: hidden;
}

.FilterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--c-primary);

  padding: 1.2rem 0;

  cursor: pointer;

  .icon {
    flex-shrink: 0;
    margin-left: $gap * 0.5;
  }
}

.Filter--opened .FilterInner {
  height: auto;
}
