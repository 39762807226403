$colors: (
  'primary': '#f5493a',
  'base': '#2c2c2c',
  'grey': '#8d8d8d',
  'success': '#48a504',
  'white': '#ffffff',
);

:root {
  @each $key, $value in $colors {
    --c-#{$key}: #{$value};
  }
}

.color--inherit.color--inherit {
  color: inherit;
}

@each $key, $value in $colors {
  .color--#{$key}.color--#{$key} {
    color: var(--c-#{$key});
  }
}
