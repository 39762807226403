.Button {
  width: 100%;
  padding: 1rem 0;
  border: 0.1rem solid var(--c-white);
  border-radius: 1rem;

  background-color: var(--c-white);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.Button--primary {
  color: var(--c-primary);
  border-color: var(--c-primary);
}

.Button--secondary {
  color: var(--c-grey);
}

.Button + .Button {
  margin-top: 1.5rem;
}
