.Loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: $gap * 4;
  background-color: #fff;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity $timing-fast, pointer-events $timing-fast, visibility $timing-fast;

  svg {
    margin-top: auto;
  }
}

.Loader--show {
  opacity: 1;
  pointer-events: initial;
  visibility: visible;
}

.LoaderBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../../images/loader-bg.jpg');
  background-size: cover;
  opacity: 0.6;
  z-index: -1;
}
