.Text--base {
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: 400;
}

.Text--h1 {
  font-size: 2.5rem;
  line-height: 3.3rem;
  font-weight: 600;
}

.Text--h2 {
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 600;
}

.Text--small {
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
}

$aligns: left, center, right;
@each $value in $aligns {
  .Text--#{$value} {
    text-align: $value;
  }
}

$weights: 400, 500, 600, 700;
@each $value in $weights {
  .Text--#{$value} {
    font-weight: $value;
  }
}
