.Sidebar {
  --border-radius: #{$border-radius * 2};

  position: absolute;
  top: 0;

  width: 36rem;
  height: 100vh;

  background-color: rgba(255, 255, 255, 0.9);

  overflow: auto;

  transition: transform 300ms;
}

.Sidebar--left {
  left: 0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transform: translateX(-100%);
}

.Sidebar--right {
  right: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  transform: translateX(100%);
}

.Sidebar--opened {
  transform: translate(0);
}

.SidebarInner {
  padding: var(--border-radius);
}

.SidebarPhoto {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 16/9;
  object-fit: cover;
  margin-bottom: $gap * 1;
}

.SidebarTitle {
  margin-bottom: $gap * 1;
}

.SidebarActions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: $gap * 2;
}

.SidebarAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  opacity: 0.6;

  transition: opacity $timing-fast;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.SidebarAction__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $gap * 0.6;

  width: 4.8rem;
  height: 4.8rem;
  border: 0.2rem solid var(--c-primary);
  border-radius: 50%;

  font-size: 1.6rem;
}

.SidebarBullets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $gap * 0.5;
  margin-bottom: $gap;
}

.SidebarBullets__item {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  padding: $gap $gap * 2;
  border-radius: $gap * 5;

  background-color: var(--c-white);

  .icon {
    flex-shrink: 0;
    margin-right: $gap;
  }
}

.SidebarDescription {
  margin-bottom: $gap * 2;

  ol,
  ul {
    text-align: left;
    padding-left: 1rem;
  }
}

.SidebarPointsOfInterest {
  display: flex;
  align-items: center;
  color: var(--c-primary);

  span {
    margin-left: 0.5rem;
  }
}

.SidebarGallery {
  display: flex;
  gap: $gap;
  overflow: auto;
  margin-top: $gap;
  padding-bottom: $gap;
}

.SidebarGallery__item {
  width: 100%;
  max-width: 27%;
  flex-shrink: 0;
  aspect-ratio: 3/4;
  object-fit: cover;
  border-radius: 0.8rem;
}
