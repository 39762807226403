.AudioPlayer {
  position: absolute;
  bottom: $gap * 2;
  left: $gap * 2;

  z-index: 99;
}

.AudioPlayer audio {
  display: block;

  width: 100vw;
  max-width: 50rem;
}
