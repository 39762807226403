.Toggler {
  position: absolute;
  top: $gap * 2;
  left: $gap * 2;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.5rem;
  height: 4.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;

  transition: background-color $timing-fast, color $timing-fast;

  cursor: pointer;

  svg {
    transition: color $timing-fast;
  }

  &:hover,
  &:focus {
    background-color: #fff;
    color: var(--c-primary);
  }
}

.Toggler--filter {
  top: $gap * 7;
}

.Toggler--language {
  top: auto;
  bottom: $gap * 2;
  font-weight: 500;
  font-size: 1.3rem;
}
